import React from 'react'
import { Box, BoxProps, Heading, Image, HeadingProps, Text } from 'theme-ui'

import { Container } from './Container'
import heroImage from '../images/padlock.png'

interface HeroProps extends BoxProps {
  children?: React.ReactNode
}

interface HeroBodyProps extends HeadingProps {
  children: React.ReactNode
}
interface HeroTitleProps extends HeadingProps {
  children: React.ReactNode
}

interface HeroSubtitleProps extends HeadingProps {
  children: React.ReactNode
}

export const HeroBody = ({ children, ...rest }: HeroBodyProps) => {
  return (
    <Box variant="hero.body" {...rest}>
      {children}
    </Box>
  )
}

export const HeroTitle = ({
  children,
  as = 'h1',
  sx,
  ...rest
}: HeroTitleProps) => {
  return (
    <Heading as={as} variant="hero.title" sx={{ ...sx }} {...rest}>
      {children}
    </Heading>
  )
}

export const HeroSubtitle = ({
  children,
  as = 'p',
  ...rest
}: HeroSubtitleProps) => {
  return (
    <Text variant="hero.subtitle" as={as} {...rest}>
      {children}
    </Text>
  )
}

export const Hero = ({ children, sx, ...rest }: HeroProps) => {
  return (
    <Box
      variant="hero.wrapper"
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...rest}
    >
      {children && (
        <Box sx={{ position: 'relative', zIndex: 2 }}>
          <Container>{children}</Container>
        </Box>
      )}

      <Image
        src={heroImage}
        alt=""
        sx={{
          position: 'absolute',
          top: ['-5%', null, 'auto', 'auto', '0'],
          right: ['-15%', '-10%', 'auto', 'auto', '5%', '-15%'],
          bottom: ['auto', 'auto', '-5%', '-5%', 'auto'],
          left: ['auto', 'auto', '-5%', '20%', 'auto'],
          maxWidth: ['70%', '60%', '70%', '50%', '80%', '50%'],
          mixBlendMode: 'multiply',
          pointerEvents: 'none',
          zIndex: [0, 0, 1, 1],
        }}
        data-sal="slide-left"
        data-sal-duration="300"
        data-sal-delay={800}
        data-sal-easing="ease"
      />
    </Box>
  )
}
