import { Helmet } from 'react-helmet'
import { Heading } from 'theme-ui'

import { Container } from '../components/Container'
import { Footer } from '../components/Footer'
import { Hero } from '../components/Hero'
import { Section } from '../components/Section'
import { BaseLayout } from '../layout/base'

const NotFoundPage = () => {
  return (
    <BaseLayout>
      <Helmet>
        <title>Stránka nenalezena | Privy</title>
      </Helmet>

      <main>
        <Hero />

        <Section sx={{ backgroundColor: 'brandPrimary.100' }}>
          <Container>
            <Heading sx={{ textAlign: 'center' }} as="h1">
              Stránka nenalezena
            </Heading>
          </Container>
        </Section>

        <Footer />
      </main>
    </BaseLayout>
  )
}

export default NotFoundPage
